<template>
  <Banner></Banner>
  <Download></Download>
  <Offer></Offer>
  <Comment></Comment>
  <Download></Download>
  <Footer></Footer>
</template>
<script>
  import Banner from "./components/BannerChongya"
  import Offer from "./components/OfferChongya"
  import Comment from "./components/CommentChongya"
  import Download from "./components/DownloadChongya"
  import Footer from "./components/FooterChongya"

  export default {
    name: 'App',
    components: {
      Banner,
      Comment,
      Offer,
      Footer,
      Download,
    },
  }
</script>
<style>
* {
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
}

a,a:link,a:visited {
  text-decoration: none;
  color: inherit;
}

hr {
  height: 1px;
  background-color: #E0E0E0;
  border: 0;
}


.flex {
  display: flex;
  display: -webkit-flex;
}

.open-in-browser img {
  position: absolute;
  right: 0;
  top: 0;
  width: 75%;
  z-index: 1000;
}
.overlay {
  background-color: black;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: alpha(Opacity=75);
  -moz-opacity: 0.75;
  opacity: 0.75;
  z-index: 100;
}

header {
  position: -webkit-sticky;
  /*position: sticky;*/
  top: 0;
  z-index: 100;
  width: 100%;
}
/*header>div {*/

/*    background-color: white;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);*/
/*    border-bottom: 1px solid rgb(32 33 36 / 28%);*/
/*}*/

header div a {

  font-weight: 500;
}
header div span {
  cursor: pointer;

}
header>div>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
  font-size: 1.4rem;
}
.duckyPNG span i {
  position: absolute;
  top: 0.4rem;
  right: 1.3rem;
  border: 6px solid #565656;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  vertical-align: middle;
}
.itema {
  padding-right: .3rem;
}
.itema[name]::after {
  content: '';
  display: inline-block;
  margin-left: .48rem;
  margin-bottom: 4px;
  top: 50%;
  right: .48rem;
  transform: translateY(-50%);
  width: .776667rem;
  height: .336667rem;
  border-left: 1px solid #070707;
  border-bottom: 1px solid #030303;
  transform: translateY(-2px) rotateZ(-45deg);
}
#sel {
  white-space: nowrap;
  margin-right: 2.3rem;
}
.icon {
  border-radius: 20%;
  display: block;
  width: 44px;
  height: 44px;
}

.icon img {
  width: 100%;
}

.title-text {
  margin-left: 1rem;
}

.header-right {
  font-size: 1.8rem;
}


#wai {
  cursor:pointer;
  position: absolute;
  top: .5rem;
  color: #565656;
  font-size: 1rem;
  font-weight: 400;
}

.title {
  color: #e0812c;
  font-weight: 900;
  font-size: 1.2rem;
  margin-bottom: .3rem;
}

/*body*/
.body {
  background-color: white;
}
.duckyPNG img {
  width: 100%;
  max-width: 30rem;
  transform: translateY(4px);
}

.duckyPNG {
  width: 100%;
  background-image: url("~@/assets/images/chongya-background.png");
  background-size: cover;
  justify-content: flex-end;
}
.duckyPNG>div {
  margin: 4.5rem .5rem 0 0 ;
  overflow: hidden;
}


.slogan {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 1.2rem;
}

.main > div {
  width: 32%;
}

.main {
  padding: 0 10%;
  justify-content: center;
  flex-direction: column;

  text-align: start;
  align-items: start;
}

.main img {
  width: 100%;
}

.main > div > div:last-child {
  font-weight: 400;
  color: #7f7f7f;
  line-height: 1.5rem;
}

.main-info-item {
  border-radius: 6px;
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: 500;
  color: #7f7f7f;
  box-sizing: border-box;
}

.main > div > div:nth-child(2) {
  margin: 1.5rem 0;
  font-size: 1.1rem;
}

/*main*/

/*comment*/
.comment-title {
  margin: 3rem 0 3rem 0;
  text-align: center;
  font-size: 1.2rem;
}

.comment-info-pan {
  overflow: hidden;
}

.comment-info-pan div {
  font-size: .9rem;
}

.comment-info-item > div > div:first-child {
  display: flex;
}

.comment-info-item > div:last-child {
  padding: 0 .5rem;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 4.5rem;
}
.comment-info-item > div:first-child {
  display: flex;
  justify-content: space-between;
  padding: 0 .3rem;
}

.comment-info-item-tar {
  display: flex;
  align-items: center;
  margin-right: .5rem;
}

.comment-info-item hr {
  margin: 1rem 0 2rem 0;
}

.comment-info-item img {
  border-radius: 50%;
}
.comment-info-item p {
  color: black;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 7rem;
}

.comment-info-item {
  height: 14rem;
  padding: 1.2rem;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #7f7f7f;
  box-sizing: border-box;
  display: inline-table;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}


.star-img {
  width: 17px;
  height: 17px;
  margin-right: 5px;
}
.comment-info-item>div:first-child {
  white-space: nowrap;
}
.comment-parent > div {
  position: relative;
}
#buttons {
  position: absolute;
  height: .13333rem;
  bottom: -.53333rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
#buttons span {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #e0e0e0;
  margin-right: 4px;
}

#prev span {
  right: .8rem;
  border-bottom: 0.18rem solid #FFF;
  border-left: 0.18rem solid #FFF;
}
#prev {
  left: 1%;
}
#next span{
  left: .8rem;
  border-top: 0.18rem solid #FFF;
  border-right: 0.18rem solid #FFF;
}
#next {
  right: 1%;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.85333rem;
  height: 2.85333rem;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  cursor: pointer;
}
.arrow span {
  position: absolute;
  display: inline-block;
  height: .6rem;
  width: .6rem;
  transform: rotate(45deg);
}


@keyframes show-comment-right-0 {
  0% {

  }
  100% {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}

@keyframes show-comment-right-1 {
  0% {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}

@keyframes show-comment-right-2 {
  0% {
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
  100% {
    transform: translateX(-200%);
    -ms-transform: translateX(-200%);
    -webkit-transform: translateX(-200%);
  }
}

@keyframes show-comment-right-3 {
  0% {
    transform: translateX(-200%);
    -ms-transform: translateX(-200%);
    -webkit-transform: translateX(-200%);
  }
  100% {
    transform: translateX(-300%);
    -ms-transform: translateX(-300%);
    -webkit-transform: translateX(-300%);
  }
}

@keyframes show-comment-right-4 {
  0% {
    transform: translateX(-300%);
    -ms-transform: translateX(-300%);
    -webkit-transform: translateX(-300%);
  }
  100% {
    transform: translateX(-400%);
    -ms-transform: translateX(-400%);
    -webkit-transform: translateX(-400%);
  }
}

@keyframes show-comment-right-5 {
  0% {
    transform: translateX(-400%);
    -ms-transform: translateX(-400%);
    -webkit-transform: translateX(-400%);
  }
  100% {
    transform: translateX(-500%);
    -ms-transform: translateX(-500%);
    -webkit-transform: translateX(-500%);
  }
}


@keyframes show-comment-left-0 {
  0% {
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
  100% {

  }
}

@keyframes show-comment-left-1 {
  0% {
    transform: translateX(-200%);
    -ms-transform: translateX(-200%);
    -webkit-transform: translateX(-200%);
  }
  100% {
    transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}

@keyframes show-comment-left-2 {
  0% {
    transform: translateX(-300%);
    -ms-transform: translateX(-300%);
    -webkit-transform: translateX(-300%);
  }
  100% {
    transform: translateX(-200%);
    -ms-transform: translateX(-200%);
    -webkit-transform: translateX(-200%);
  }
}

@keyframes show-comment-left-3 {
  0% {
    transform: translateX(-400%);
    -ms-transform: translateX(-400%);
    -webkit-transform: translateX(-400%);
  }
  100% {
    transform: translateX(-300%);
    -ms-transform: translateX(-300%);
    -webkit-transform: translateX(-300%);
  }
}

@keyframes show-comment-left-4 {
  0% {
    transform: translateX(-500%);
    -ms-transform: translateX(-500%);
    -webkit-transform: translateX(-500%);
  }
  100% {
    transform: translateX(-400%);
    -ms-transform: translateX(-400%);
    -webkit-transform: translateX(-400%);
  }
}

@keyframes show-comment-left-5 {
  0% {
    transform: translateX(-500%);
    -ms-transform: translateX(-500%);
    -webkit-transform: translateX(-500%)
  }
  100% {
    transform: translateX(-500%);
    -ms-transform: translateX(-500%);
    -webkit-transform: translateX(-500%);
  }
}

/*comment end*/

/*footer*/
footer {
  padding: 0 10% 1.4rem 10%;
}

.footer-text {
  color: #7f7f7f;
  font-weight: 500;
}

.footer-top {
  text-align: center;
  margin-bottom: 1.16rem;
}

.footer-center p {
  display: inline-block;
  line-height: 1.9rem;
}

.footer-center > div {
  flex-direction: column;
}

.footer-center {
  text-align: left;
  flex-wrap: wrap;
  justify-content: space-between;
}

footer a[class=flex] {
  margin-bottom: .5rem;
  /*align-items: center;*/
}

footer a span {
  margin-left: .7rem;
  height: 1rem;
}

footer img {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
}

.footer-bottom {
  line-height: 2rem;
  font-size: .88rem;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 1rem;
}

.footer-small, .footer-bigger {
  margin: 1.5rem 0;
}
.footer-small {
  display: none;
  justify-content: space-between;
}
.footer-small>div {
  flex-direction: column;
  justify-content: flex-start;
}
.footer-small > div:last-child>div:first-child {
  margin-bottom: 3.3rem;
}
.footer-small .footer-img ,.footer-bigger .footer-img{
  margin-top: .4rem;
}
.footer-small>div>div>div:first-child {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.tawkstar, .service, .privacy {
  cursor: pointer;
}
/*footer end*/

@media all and (max-width: 419.999px) and (min-width: 150px) {
  .footer-bigger {
    display: none;
  }

  .footer-small {
    display: flex;
  }

  .footer-bottom-addr-all {
    display: none;
  }
  .footer-text {
    font-size: 1rem;
  }
  .main {
    align-items: center;
  }

  .main > div {
    width: 100%;
  }

  .main > div > div:first-child {
    margin-top: 3.5rem;
  }

  .slogan {
    margin-bottom: 0;
  }
  .comment-info {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    animation: show-comment-right-0 0.5s ease 0s forwards normal;
  }
  .duckyPNG > div {
    margin: 8.5rem 0.5rem 0 0;
    min-width: 5rem;
    max-width: 12rem;
  }
  .comment-info-pan div{
    font-size: .7rem !important;
  }
  .star-img {
    width: 13px;
    height: 13px;
    margin-right: 3px;
  }
  .comment-info-item-tar img {
    width: 36px;
    height: 36px;
  }

}

@media all and (max-width: 499.9999px) and (min-width: 420px) {
  .footer-bigger {
    display: none;
  }

  .footer-small {
    display: flex;
  }

  .footer-bottom-addr-all {
    display: none;
  }
  .footer-text {
    font-size: 0.8rem;
  }
  .main {
    align-items: center;
  }

  .main > div {
    width: 100%;
  }

  .main > div > div:first-child {
    margin-top: 3.5rem;
  }

  .slogan {
    margin-bottom: 0;
  }
  .comment-info {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    animation: show-comment-right-0 0.5s ease 0s forwards normal;
  }
  .duckyPNG > div {
    margin: 3.6rem .5rem 0 0 ;
    min-width: 10rem;
    max-width: 15rem;
  }
}

@media all and (max-width: 799.9999px) and (min-width: 500px) {
  .footer-bigger {
    display: none;
  }

  .footer-small {
    display: flex;
  }

  .footer-bottom-addr-all {
    display: none;
  }
  .footer-text {
    font-size: 1rem;
  }
  .main {
    align-items: center;
  }

  .main > div {
    width: 75%;
  }

  .main > div > div:first-child {
    margin-top: 3.5rem;
  }
  .comment-info {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    animation: show-comment-right-0 0.5s ease 0s forwards normal;
  }
  .comment-info-item {
    width: 68%;
    margin-left: 16%;
    margin-right: 16%;
  }

  .slogan {
    margin-bottom: 0;
  }

  .duckyPNG > div {
    margin: 6rem 1rem 0 0 ;
    min-width: 15rem;
    max-width: 20rem;
  }
}

@media all and (max-width: 1399.9999px) and (min-width: 800px) {
  .footer-small {
    display: none;
  }

  .footer-bigger {
    display: flex;
  }

  .footer-bottom-addr {
    display: none;
  }

  .main {
    justify-content: space-between;
    flex-direction: row;
  }
  .comment-info {
    display: flex;
    flex-wrap: wrap;
    margin: 0 10%;
    justify-content: space-between;
  }
  .comment-info-item {
    width: 47%;
    margin: 1rem 0;
    min-width: 19.7rem;
  }
  #buttons, #next, #prev {
    display: none !important;
  }

}

@media all and (max-width: 1599.9999px) and (min-width: 1400px) {
  .footer-small {
    display: none;
  }

  .footer-bigger {
    display: flex;
  }

  .footer-bottom-addr {
    display: none;
  }

  .main {
    justify-content: space-between;
    flex-direction: row;
  }    .comment-info {
         display: flex;
         flex-wrap: wrap;
         margin: 0 10%;
         justify-content: space-between;
       }
  .comment-info-item {
    width: 32%;
    margin: 1rem 0;
  }
  #buttons, #next, #prev {
    display: none !important;
  }
}

@media all and (max-width: 2099.9999px) and (min-width: 1600px) {
  .footer-small {
    display: none;
  }

  .footer-bigger {
    display: flex;
  }

  .footer-bottom-addr {
    display: none;
  }

  .main {
    justify-content: space-between;
    flex-direction: row;
  } .comment-info {
      display: flex;
      flex-wrap: wrap;
      margin: 0 10%;
      justify-content: space-between;
    }
  .comment-info-item {
    width: 32%;
    margin: 1rem 0;
  }
  #buttons, #next, #prev {
    display: none !important;
  }
}

@media all and (max-width: 3000px) and (min-width: 2100px) {
  .footer-small {
    display: none;
  }

  .footer-bigger {
    display: flex;
  }

  .footer-bottom-addr {
    display: none;
  }

  .main {
    justify-content: space-between;
    flex-direction: row;
  } .comment-info {
      display: flex;
      flex-wrap: wrap;
      margin: 0 10%;
      justify-content: space-between;
    }
  .comment-info-item {
    width: 32%;
    margin: 1rem 0;
  }
  #buttons, #next, #prev {
    display: none !important;
  }
}

</style>
